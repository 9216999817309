import { Injectable } from '@angular/core';
import testingVariables from './testing.json'
import { Database, getDatabase, ref, child, remove, get, set, connectDatabaseEmulator, orderByChild, query } from 'firebase/database'
import { ClausesObject, FirebaseClauses } from 'src/class/clauses';


@Injectable({
  providedIn: 'root',
})
export class UploadService {

  admins = ["hans.taylor@dorado.com.au", "jason.black@dorado.com.au", "danielle.nicholas@dorado.com.au"];

  public database: Database = getDatabase()

  constructor(
    // private storage: AngularFireStorage,
  ) {
    if(testingVariables.testing) connectDatabaseEmulator(this.database, "localhost", 9000);
    this.fetchAdmins()
  }

  async fetchAdmins() {
    const adminRef = ref(this.database, "admins")
    const admins = await get(adminRef)
    if(admins.exists()) this.admins = admins.val()
  }
  

  async fetchClauses(dealType: string): Promise<ClausesObject> {
    
    const clausesRef = ref(this.database, `masterSheets/${dealType}/clauses`)

    const sortedClausesRef = query(clausesRef, orderByChild("order"))

    const clauses = await get(sortedClausesRef)

    return clauses.exists() ? new ClausesObject(clauses.val()) : undefined
  }


  /**
   * Attempts to set the current clauses in firebase.
   * @param clauses 
   * @param dealType 
   * @returns Nothing in the event of success, and an error message in the event of failure
   */
  async setClauses(clauses: FirebaseClauses, dealType: string) {
    try {
      const clausesRef = ref(this.database, `masterSheets/${dealType}/clauses`)

      return await set(clausesRef, clauses)
    } catch(error) {
      return error.message ?? "An unknown error occurred"
    }
  }



  /**
   * Temporary function to convert the old data structure to the new one
   * @returns 
   */
   async convertClauses() {
    //change this to masterSheetsNew when doing live
    const from = "masterSheetsNew"

    const to = "masterSheets"

    const fromRef = ref(this.database, from)
    const toRef = ref(this.database, to)

    const fromClauses = await get(fromRef)

    if(!fromClauses.exists()) return

    // if(from === to) await remove(fromRef)
    
    const newClauses = Object.fromEntries(Object.entries(fromClauses.val()).map(([dealType, clauses]: any) => {
      console.log(dealType, clauses)
      const newClauseEntries = Object.entries(clauses.clauses).map(([header, clause]: any) => {
        console.log(clause)
        clause.content = clause.content.map((content: any) => {
          if(content.content) {
            content.text = content.content
            content.text = content.text.map(subText => subText[0])
            delete content.content
          }

          if(content.inline !== undefined) {
            content.type = content.inline ? "inline" : "list"
            delete content.inline
          }

          return content
        }).filter(x => x)
        return [header, clause]
      })
      return [dealType, {clauses: Object.fromEntries(newClauseEntries)}]
    }))

    await set(toRef, newClauses)
  }
}
